<template>
  <div
    class="
      bg-primary-light
      border-none
      w-11/12
      m-auto
      md:w-2/4
      rounded-xl
      p-5
      md:p-10
    "
  >
    <p class="text-lg text-gray-50 mb-5">
      <span @click="$router.push('/user')"
        ><i class="fas fa-arrow-left mr-3 cursor-pointer"></i></span
      >Admin panel
    </p>
    <p class="text-gray-50">All users :</p>
    <div
      class="bg-primary border-none rounded-xl my-5 p-5"
      v-for="user in users"
      :key="user"
    >
      <p class="text-center text-l text-gray-50 break-words mb-1">
        {{ user.did }}
      </p>
      <p class="text-center text-sm text-gray-400 mb-1">
        Verifiable credentials :
      </p>
      <div
        v-for="verifiableCredential in user.verifiableCredentials"
        :key="verifiableCredential"
      >
        <p
          v-if="
            Object.keys(verifiableCredential.credentialSubject)[
              Object.keys(verifiableCredential.credentialSubject).length - 1
            ] != 'award'
          "
          class="text-center text-l text-gray-50 break-words mb-1"
        >
          {{
            Object.keys(verifiableCredential.credentialSubject)[
              Object.keys(verifiableCredential.credentialSubject).length - 1
            ] +
            ' : ' +
            verifiableCredential.credentialSubject[
              Object.keys(verifiableCredential.credentialSubject)[
                Object.keys(verifiableCredential.credentialSubject).length - 1
              ]
            ]
          }}
        </p>
        <p v-else class="text-center text-l text-gray-50 break-words mb-1">
          {{
            verifiableCredential.credentialSubject[
              Object.keys(verifiableCredential.credentialSubject)[
                Object.keys(verifiableCredential.credentialSubject).length - 1
              ]
            ].split('; ')[0] +
            ' : ' +
            verifiableCredential.credentialSubject[
              Object.keys(verifiableCredential.credentialSubject)[
                Object.keys(verifiableCredential.credentialSubject).length - 1
              ]
            ].split('; ')[1]
          }}
        </p>
      </div>
      <div class="text-center mt-5">
        <p
          class="
            inline-block
            text-secondary text-xs
            font-semibold
            border-solid border-2 border-secondary
            rounded-lg
            cursor-pointer
            py-3
            px-5
          "
          @click="deleteUser(user.did)"
        >
          Delete user
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import { useRouter } from 'vue-router';
export default {
  async setup() {
    const $cookies = inject('$cookies');
    const $api = inject('$api');
    const router = useRouter();
    const users = ref({});

    function logout() {
      $cookies.remove('token');
      router.push('/');
    }

    const isAdmin = (await $api.isAdmin()).data.isAdmin;
    if (!isAdmin) {
      window.location.replace('/user');
    } else {
      users.value = (await $api.getAllUsers()).data.reverse();
    }

    async function deleteUser(did) {
      await $api.deleteUser(did);
      users.value = (await $api.getAllUsers()).data.reverse();
    }

    return {
      users,
      logout,
      deleteUser,
    };
  },
};
</script>
