<template>
  <div
    class="bg-primary-light border-none rounded-xl m-auto w-4/5 md:w-2/4 p-5 md:p-10"
  >
    <p class="text-lg text-gray-50 mb-5">User information</p>
    <hr class="border-t-1 border-white border-opacity-20 w-full py-5" />
    <div v-if="!loadingInfo">
      <p class="text-sm text-gray-400 mb-2">
        <i class="fas fa-portrait mr-1"></i>User DID
      </p>
      <p class="text-l text-gray-50 break-words mb-5">
        {{ user.did }}
      </p>
      <p class="text-sm text-gray-400 mb-2">
        <i class="fas fa-shield-alt mr-1"></i>Verifiable credentials :
      </p>
      <div
        v-for="verifiableCredential in user.verifiableCredentials"
        :key="verifiableCredential"
      >
        <p
          v-if="
            Object.keys(verifiableCredential.credentialSubject)[
              Object.keys(verifiableCredential.credentialSubject).length - 1
            ] != 'award'
          "
          class="text-l text-gray-50 break-words mb-1"
        >
          {{
            Object.keys(verifiableCredential.credentialSubject)[
              Object.keys(verifiableCredential.credentialSubject).length - 1
            ] +
            ' : ' +
            verifiableCredential.credentialSubject[
              Object.keys(verifiableCredential.credentialSubject)[
                Object.keys(verifiableCredential.credentialSubject).length - 1
              ]
            ]
          }}
        </p>
        <p v-else class="text-l text-gray-50 break-words mb-1">
          {{
            verifiableCredential.credentialSubject[
              Object.keys(verifiableCredential.credentialSubject)[
                Object.keys(verifiableCredential.credentialSubject).length - 1
              ]
            ].split('; ')[0] +
            ' : ' +
            verifiableCredential.credentialSubject[
              Object.keys(verifiableCredential.credentialSubject)[
                Object.keys(verifiableCredential.credentialSubject).length - 1
              ]
            ].split('; ')[1]
          }}
        </p>
      </div>
      <div v-if="dynamicQrCodeSrc" class="flex justify-center gap-10 mb-10">
        <img
          :src="user.verifiableCredential.credentialSubject.image"
          alt=""
          class="w-72 h-72"
        />
        <img :src="dynamicQrCodeSrc" alt="" class="w-72 h-72" />
      </div>
      <div v-else class="flex justify-center mb-10">
        <custom-loader class="mr-2 mt-0.5" color="#028ef6" size="50px">
        </custom-loader>
      </div>
    </div>
    <div v-else class="flex justify-center mb-10">
      <custom-loader class="mr-2 mt-0.5" color="#028ef6" size="50px">
      </custom-loader>
    </div>
    <p v-if="isAdmin" class="text-gray-400 text-xs underline mt-5">
      <router-link to="/admin"
        ><i class="fas fa-user-shield mr-1"></i>Admin panel</router-link
      >
    </p>
    <div class="flex justify-center mt-5 gap-5">
      <p
        class="inline-block text-white font-semibold border-solid border-2 border-white rounded-lg cursor-pointer py-3 px-5"
        @click="logout"
      >
        Sign out
      </p>
      <p
        class="inline-block text-secondary font-semibold border-solid border-2 border-secondary rounded-lg cursor-pointer py-3 px-5"
        @click="deleteAccount"
      >
        Delete account
      </p>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import confetti from 'canvas-confetti';
import { createGif } from '../plugins/qrGif';
export default {
  async setup() {
    const $cookies = inject('$cookies');
    const $api = inject('$api');
    const user = ref({});
    const isAdmin = ref(false);
    const loadingInfo = ref(true);
    const loadingGif = ref(true);
    const dynamicQrCodeSrc = ref(null);

    function logout() {
      $cookies.remove('token');
      window.location.replace('/');
    }

    async function deleteAccount() {
      await $api.deleteMe();
      $cookies.remove('token');
      window.location.replace('/');
    }

    function showConfetti() {
      var duration = 5 * 1000;
      var animationEnd = Date.now() + duration;
      var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

      function randomInRange(min, max) {
        return Math.random() * (max - min) + min;
      }

      var interval = setInterval(function () {
        var timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        var particleCount = 50 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
          })
        );
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
          })
        );
      }, 250);
    }

    $api.getUser().then((res) => {
      user.value = res.data;
      loadingInfo.value = false;
      createGif(JSON.stringify(user.value.verifiableCredential)).then((res) => {
        loadingGif.value = false;
        dynamicQrCodeSrc.value = res;
      });
      qrCodeData.value = JSON.stringify(user.value.verifiableCredential);
    });
    $api.isAdmin().then((res) => {
      isAdmin.value = res.data.isAdmin;
    });

    // showConfetti();

    return {
      user,
      isAdmin,
      loadingGif,
      loadingInfo,
      dynamicQrCodeSrc,
      logout,
      deleteAccount,
    };
  },
};
</script>
