<template>
  <div
    class="
      bg-primary-light
      border-none
      w-11/12
      md:w-2/4
      rounded-xl
      m-auto
      p-5
      md:p-10
    "
  >
    <p class="text-center text-lg text-gray-50 mb-5">
      <img
        :src="require('/assets/images/small_mydid.png')"
        alt=""
        class="w-8 mx-auto mb-2"
      />myDid authentication
    </p>
    <p class="text-center text-sm text-gray-400 mb-1">
      Connect safely using myDid wallet smartphone application.
    </p>
    <p class="text-center text-sm text-gray-400 mb-10">
      myDid provides decentralized identity solutions for secure and
      frictionless authentication.
    </p>
    <div class="flex justify-center gap-10 mb-5">
      <div>
        <p class="text-center text-sm text-white">First time here ?</p>
        <p class="text-center text-sm text-gray-400 mb-3">Use register :</p>
        <div class="flex justify-center">
          <router-link to="/signup">
            <p
              class="
                text-sm text-white text-center
                bg-secondary
                font-semibold
                w-24
                md:w-48
                rounded-xl
                py-3
                px-5
                cursor-pointer
              "
            >
              Register
            </p>
          </router-link>
        </div>
      </div>
      <div>
        <p class="text-center text-sm text-white">Already registered ?</p>
        <p class="text-center text-sm text-gray-400 mb-3">Use sign in :</p>
        <div class="flex justify-center">
          <router-link to="/signin"
            ><p
              class="
                text-sm text-white text-center
                bg-secondary
                font-semibold
                w-24
                md:w-48
                rounded-xl
                py-3
                px-5
                cursor-pointer
              "
            >
              Sign in
            </p></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
