<template>
  <div
    class="bg-primary-light border-none w-11/12 m-auto md:w-2/4 rounded-xl p-5 md:p-10"
  >
    <p class="text-lg text-gray-50 mb-5">
      <span @click="$router.push('/')"
        ><i class="fas fa-arrow-left mr-4 cursor-pointer"></i></span
      >Register
    </p>
    <hr class="border-t-1 border-white border-opacity-20 w-full py-5" />
    <p class="text-center text-l text-gray-50 mb-2">
      Scan QR code with the dedicated application to be registered :
    </p>
    <div class="flex justify-center mb-5">
      <custom-loader
        v-if="loading"
        class="mr-2 mt-0.5"
        color="#028ef6"
        size="20px"
      >
      </custom-loader>
      <p
        class="text-center text-l"
        :class="
          status.indexOf('Error') != -1 ? 'text-red-500' : 'text-secondary'
        "
      >
        {{ status
        }}<span
          v-if="
            status.indexOf('Error') != -1 && status.indexOf('Account') != -1
          "
          class="text-sm underline"
          ><br /><!-- SSE not launching if using router-link with no refresh --><a
            href="/signin"
            >Since you are already registered, you can try to sign in by
            clicking here</a
          ></span
        >
      </p>
    </div>
    <qrcode-vue
      v-if="qrCodeData"
      :value="qrCodeData"
      :size="270"
      level="H"
      class="bg-white mx-auto mb-5 p-4"
    />
    <div v-if="isMobile()">
      <p class="text-center text-3xl text-gray-50 my-5">OR</p>
      <p class="text-center text-l text-gray-50 mb-2">
        <i class="fas fa-mobile-screen mr-1"></i>Click below to directly open
        the app you are using if you are on mobile :
      </p>
      <div class="flex justify-center gap-8 my-5">
        <a :href="shortenLinkMydid" target="_blank"
          ><img
            :src="require('/assets/images/mydid_app_logo.png')"
            alt=""
            class="w-28 mx-auto border-white border"
        /></a>
        <a :href="shortenLinkEchoslife" target="_blank"
          ><img
            :src="require('/assets/images/echoslife_app_logo.jpg')"
            alt=""
            class="w-28 mx-auto border-white border"
        /></a>
      </div>
    </div>
    <p
      v-if="verifiableCredentials.length > 0"
      class="text-center text-sm text-gray-400 mt-10 mb-1"
    >
      <i class="fas fa-shield-alt mr-1"></i>Verifiable credentials needed :
    </p>
    <p
      class="text-center text-sm text-gray-100"
      v-for="vc in verifiableCredentials"
      :key="vc"
    >
      {{ vc }}
    </p>
  </div>
</template>

<script>
import { ref, inject, onBeforeUnmount } from 'vue';
export default {
  async setup() {
    const $api = inject('$api');
    const $cookies = inject('$cookies');
    const qrCodeData = ref('');
    const shortenLinkMydid = ref('');
    const shortenLinkEchoslife = ref('');
    const status = ref('');
    const verifiableCredentials = ref({});
    const loading = ref(null);

    onBeforeUnmount(() => {
      $api.closeChallengeValidation();
    });

    const newChallenge = (await $api.createChallengeSignup()).data;
    // qrCodeData.value = JSON.stringify(newChallenge);
    qrCodeData.value =
      'https://mydid.link?data=' +
      encodeURIComponent(JSON.stringify(newChallenge));

    verifiableCredentials.value = newChallenge.verifiableCredentials;

    // CREATE SSE CONNECTION
    $api.waitChallengeValidation(newChallenge.challenge, (response) => {
      switch (response.status) {
        case 'waiting':
          status.value = 'Waiting scan from mobile application';
          loading.value = true;
          break;
        case 'treating':
          status.value = 'Checking your registration';
          loading.value = true;
          break;
        case 'expired':
          status.value = 'Error while registering : ' + response.message;
          loading.value = false;
          break;
        case 'validated':
          status.value = "Congratulations, you're registered. Redirecting...";
          $cookies.set('token', response.token);
          loading.value = false;
          setTimeout(() => window.location.replace('/user'), 1000);
          break;
      }
    });

    $api.more
      .shortenUrl(
        'https://mydid.link?data=' + encodeURIComponent(qrCodeData.value),
        120
      )
      .then((response) => {
        shortenLinkMydid.value = response.shortUrl;
      });

    $api.more
      .shortenUrl(
        'echoslife://app.dev?data=' + encodeURIComponent(qrCodeData.value),
        120
      )
      .then((response) => {
        shortenLinkEchoslife.value = response.shortUrl;
      });

    function isMobile() {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    }

    return {
      qrCodeData,
      shortenLinkMydid,
      shortenLinkEchoslife,
      status,
      verifiableCredentials,
      loading,
      isMobile,
    };
  },
};
</script>
